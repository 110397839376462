.formLabelName {
    color: #4A4A4A !important;
    font-weight: 600 !important;
}

.formGridList {
    border-bottom: 1px solid #D4D4D4;
    cursor: all-scroll;
}

.formInnerBox {
    background: #FCFCFC;
    border: 1px solid #E1E1E1;
    border-radius: 5px;
    padding: 20px;
}

.formLeftBox {
    /* min-width: fit-content;
    width: 100px;
    height: 33px; */
    width: 100px;
    height: 100%;
    min-width: 110px;
    min-height: 33px;
  /* max-width: 500px; */
}

.type2TextField div {
    width: 100%;
    min-width: 100px;
    height: 100%;
    min-height: 33px;
}
@media screen and (max-width: 500px) {
    .type2TextField div {
    min-width: 100%;
    }
}
@media screen and (max-width: 500px) {
    .formLeftBox{
        width: 100px;
    }
    
}
