body {
  margin: 0;
  font-family: 'Mulish', sans-serif;
  /* text-transform: capitalize; */
  color: #4A4A4A;
}

* {
  font-family: 'Mulish', sans-serif;
}

p,
label,
input,
button,
th,
td,
h1,
h2,
h3,
h4,
h5,
h6,
div,
legend,
span,
li {
  font-family: 'Mulish', sans-serif !important;
}

.Toastify__close-button {
  position: relative;
  top: 16px;
  right: 10px;
}

.u-none {
  text-decoration: unset;
}

.MuiFormHelperText-root {
  margin-left: 0px !important;

}

.authBG {
  background-image: url('./assets/images/auth_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100vh;
}

.authLayoutLogo {
  text-align: center;
  box-shadow: 0px 3px 6px #0000001F;
  padding: 10px 5px 5px 5px;
}

.authLayoutLogo img {
  width: 150px;
}

/* menu style */

.dashboardLinkBox {
  width: 150px;
  height: 30px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #d8d7d7;
  text-decoration: unset;
  color: #4a4a4a;
  font-size: 16px;
  width: -webkit-fill-available;
}

.dashboardLinkBox:last-child {
  border-right: none;
}

.activeMenu {
  background: #004D9B;
  color: white !important;
}

.dashboardBox {
  border: 1px solid #D4D4D4;
}

/* button style */

.bordersmall-btn {
  color: #004D9B !important;
  border: 1px solid #004D9B !important;
  text-transform: capitalize !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  font-weight: bold !important;
}

.bordersmall-span {
  color: #004D9B !important;
  text-transform: lowercase !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  font-weight: bold !important;
}

.topBarTypo {
  color: #4A4A4A !important;
  font-size: 26px !important;
  font-weight: bold !important;
}

/* textfield forms style */

textarea:focus,
input:focus {
  border: none !important;
  outline: none !important;
}

.type2TextField .MuiInputBase-root {
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
}

.type2TextField input,
.type2TextField div {
  padding: 3px 10px;
}

.type2TextField fieldset {
  border-color: #D6D6D6 !important;
}

.formLeftBox {
  background: #F0F0F0;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  color: #4A4A4A;
  font-weight: 600;
  border: 1px solid #D6D6D6;
  font-size: 14px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.boxContainerBorder {
  border: 1px solid #E1E1E1;
  background: #FCFCFC;
  border-radius: 5px;
  padding: 15px;
}

.smallSize .MuiInputBase-root {
  height: 30px !important;
}

.toggleButton .saveBtn {
  background: #004D9B;
  height: 30px !important;
  color: white !important;
  text-transform: capitalize !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.toggleButton .cancelBtn {
  height: 30px !important;
  text-transform: capitalize !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  color: #004D9B !important;
  border: 1px solid #004D9B !important;
}

.tableContainer {
  border: 1px solid #E1E1E1;
  border-radius: 5px;
}

.tableContainer table thead tr {
  border-bottom: 1px solid #E1E1E1;
  background: #FCFCFC;
}

.tableContainer table thead tr th {
  color: #4A4A4A;
  font-weight: bold;
  padding: 10px 16px !important;
}

.tableContainer table tr td {
  border-left: 1px solid #E1E1E1;
  border-bottom: 0px solid #E1E1E1;
  color: #4A4A4A;
  font-weight: bold;
  padding: 10px 16px !important;
}

.loginBtn1 {
  text-transform: capitalize !important;
  font-size: 16px !important;
  font-weight: 100 !important;
  color: white !important;
  background-color: #004D9B !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
}

/* image upload */
.inputtext {
  align-items: center;
  display: flex;
  text-decoration-line: underline;
  color: #4A4A4A;
  cursor: pointer;
  margin-top: 20px;
}

.image-upload {
  display: flex;
  justify-content: center;
}

.upload-button {
  background-color: #004D9B;
  transition: 0.7s;
  color: #fff;
  border-radius: 4px;
}

/* agent from */
.TextField .MuiInputBase-root {
  border-radius: 5px;
}

.TextField input,
.TextField div {
  padding: 3px 8px;
}

.TextField fieldset {
  border-color: #D6D6D6 !important;
}

/* delete button */
.submitBtnn {
  background: #004D9B !important;
}

/* view  */
.cg-text {
  font-size: 16px !important;
  font-weight: 700 !important;
}

/* technology */
.hub-TextField .MuiInputBase-root {
  border-radius: 0px;
  max-width: 358px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

}

.hub-TextField input,
.hub-TextField div {
  padding: 3px 10px;
}

.hub-TextField fieldset {
  border-color: #D6D6D6 !important;
}

.bg-textfield h6,
.text-area-new .MuiTypography-root {
  padding: 5px 10px;
  color: #4A4A4A;
  font-weight: 600;
  border: 1px solid #D6D6D6;
  font-size: 14px;
  max-width: 525px;
  background: #F0F0F0;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* .text-area-new .MuiTypography-root {
  max-width: unset;
} */

/* video */
.icon-video {
  position: 'relative';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)' !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: 'black' !important;
}

.tableContainer1 {
  border: 1px solid #E1E1E1;
  border-radius: 5px;
}

.tableContainer1 table thead tr {
  border-bottom: 1px solid #E1E1E1;
  background: #FCFCFC;
}

.tableContainer1 table thead tr th {
  color: #4A4A4A;
  font-weight: bold;
  padding: 10px 16px !important;
}

.tableContainer1 table tr td {

  border-bottom: 0px solid #E1E1E1;
  color: #4A4A4A;
  font-weight: bold;
  padding: 10px 16px !important;
}

/* data picker */
.datapicker {
  display: flex;
}

.datapicker h6 {
  background-color: #F0F0F0;
  align-items: center;
  margin-top: 8px;
  padding: 0px 10px;
  height: 28px;
  color: #4A4A4A;
  font-size: 16px;
  width: 120px;
  border: 1px solid #D6D6D6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

}

.datapicker .MuiOutlinedInput-root {
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 30px;
  width: 180px;
  box-shadow: none;

}

.datapicker .MuiIconButton-root {
  background-color: #F0F0F0;
  border-radius: 0px;
  margin-right: -13px !important;
  height: 30px;

}

.datapicker .MuiOutlinedInput-input {
  padding: 1.5px 14px !important;
}



.class-2 fieldset {
  border-color: #D6D6D6 !important;
}

.category_list {
  text-align: left;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  overflow: hidden;
  text-align: justify;


}

/* agent vendor */
.custom-select fieldset {
  border-color: #D6D6D6 !important;
}

.bg-vendortext {
  font-size: 12px !important;
  color: #4A4A4A !important;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  cursor: pointer;
}

.container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: rgba(97, 97, 97, 0.92);
}

.container:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 0.6875rem !important;
  position: absolute;
  left: 5px;
}

.formGridListt {
  border-bottom: 1px solid #D4D4D4;
}

.phone-number-relative {
  position: relative;
  border: 1px solid #D6D6D6;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.phone-number-absolute {
  top: 0%;
  left: 0%;
  position: absolute;
  height: 100%;
  background-color: #DCDCDC;
  /* border-radius: 5px 0px 0px 5px; */
}


.mantine-input-style-copy-phone {
  height: 33px;
  appearance: none;
  resize: none;
  box-sizing: border-box;
  width: 235px;
  color: rgb(0, 0, 0);
  display: block;
  text-align: left;
  border: 0px solid #D6D6D6;
  background-color: transparent;
  padding-left: calc(2.8rem);
  padding-right: calc(0.75rem);
}

.mantine-input-style-copy-phone:focus {
  outline: none;
  border-color: #228be6;
}

.mantine-input-style-copy-phone:disabled {
  background-color: #f1f3f5;
  color: #909296;
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.mantine-input-style-copy-phone[aria-invalid] {
  color: #fa5252;
  /* border-color: #fa5252; */
}

.errorMsg {
  color: #d32f2f !important;
  font-size: 0.75rem !important;
}

/* .phone-number-absolute input {
  width: -webkit-fill-available;
  height: 33px;
  border-radius: 0px;
  border: 1px solid red;
} */

/* from datapicker 1 */
.datapicker1 {
  display: flex;
}

.datapicker1 h6 {
  background-color: #F0F0F0;
  align-items: center;
  margin-top: 8px;
  padding: 0px 10px;
  height: 28px;
  color: #4A4A4A;
  font-size: 16px;
  width: 100px;
  text-align: center;
  border: 1px solid #D6D6D6;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

}

.datapicker1 .MuiOutlinedInput-root {
  border-radius: 0px;
  width: 87%;
  height: 30px;
  box-shadow: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

}

.datapicker1 .MuiIconButton-root {
  background-color: #F0F0F0;
  border-radius: 0px;
  margin-right: -13px !important;
  height: 30px;

}

.datapicker1 .MuiOutlinedInput-input {
  padding: 1.5px 14px !important;
}

.class-2 fieldset {
  border-color: #D6D6D6 !important;
}

/* from checkbox */
.checkbox {
  margin-left: 0px !important;

}

.checkbox .MuiFormControlLabel-label {
  width: 110px;
  min-width: 82px;
  background: #F0F0F0;
  padding: 5px 10px;
  border: 1px solid #D6D6D6;
  font-size: 14px;
  color: #4A4A4A;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  word-wrap: break-word;
  display: inline-block;
}

.upload_image {
  text-transform: capitalize !important;
}

/* agent form image upload */
.inputbuttton {
  border: 1px solid #D6D6D6;
  position: relative;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  padding-left: 21px;
  align-items: center;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
}

.inputbuttton label {
  border-radius: 0px;
  background: #F0F0F0;
  font-size: 14px;
  color: #4A4A4A;
  position: absolute;
  right: 0;
  height: -webkit-fill-available;

}

.inputbutttonimage {
  border: 1px solid #D6D6D6;
  position: relative;
  width: 100%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
  padding-left: 21px;
  align-items: center;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
}

.inputbutttonimage label {
  border-radius: 0px;
  background: #F0F0F0;
  font-size: 14px;
  color: #4A4A4A;
  width: 10px;
  position: absolute;
  right: 0;
  height: -webkit-fill-available;
}

.searchBtnLog {
  background: #004D9B !important;
  height: 30px;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.clearBtnLog {
  background: #004D9B !important;
  height: 30px;
  font-size: 14px !important;
  text-transform: capitalize !important;
}

.formTextAreaField h6 {
  width: auto;
}

.formTextAreaField .MuiInputBase-root {
  max-width: 534px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: white;
}

/* .formFieldDatePicker .MuiStack-root {
  width: 100%;
} */

.formFieldDatePicker .MuiStack-root .MuiFormControl-root {
  background: white;
}

.description {
  display: -webkit-box;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  /* height: 146px !important; */
}

.description-agent {
  height: 146px !important;
  display: -webkit-box;
  -webkit-line-clamp: 6 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}


/* responsvie */
/* @media screen and (max-width: 899px) {
  .dashboardLinkBox {
      width: 97%;
      height: 30px;
      text-align: center;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #d8d7d7;
      border-right: 0px;
      text-decoration: unset;
      color: #4a4a4a;
      font-size: 16px;
    }
}
@media screen and (max-width: 600px) {
  .dashboardLinkBox {
    height: 30px;
    text-align: center;
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #d8d7d7;
    border-right: 0px;
    text-decoration: unset;
    color: #4a4a4a;
    font-size: 16px;
    width: 95%;
  }
}
 */
.dashboardLinkBox1 {
  width: 200px;
  height: 30px;
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: unset;
  color: #4a4a4a;
  font-size: 16px;
}

@media screen and (max-width: 899px) {
  .dashboardLinkBox {
    display: none !important;

  }

  .MuiContainer-root {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 899px) {
  .dashboardBox {
    border: none !important;
  }
}

.preLabelName {
  padding-top: 5px;
  padding-bottom: 5px;
}

.tox-tinymce {
  border-radius: 0px !important;
  height: 300px !important;
  max-width: 534px;
}

.tox-statusbar__branding svg {
  display: none;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* vender mobile respansive */
@media screen and (max-width: 400px) {
  .mantine-input-style-copy-phone {
    width: 100%;
  }

}

/* text contact no */
.text-input-style-copy-phone {
  height: 33px;
  box-sizing: border-box;
  width: 430px;
  color: rgb(0, 0, 0);
  display: block;
  text-align: left;
  border: 0px solid #D6D6D6;
  background-color: #fff;
  padding-left: calc(2.8rem);
  padding-right: calc(0.75rem);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.formLeftBox1 {
  width: 80px;
  height: 33px;
  background: #F0F0F0;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  color: #4A4A4A;
  font-weight: 600;
  border: 1px solid #D6D6D6;
  font-size: 14px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.formLeftBox .MuiTypography-subtitle1 {
  color: #4A4A4A;
  font-weight: 600;
  font-size: 14px;
}

.formTextAreaField .MuiTypography-subtitle1 {
  color: #4A4A4A;
  font-weight: 600;
  font-size: 14px;
}


iframe {
  overflow-y: hidden;
  margin: 5px;
  border-radius: 10px;
}


.tox-statusbar {
  display: none !important;
}

/* agent phone number  */
.TextField1 .MuiInputBase-root {
  border-radius: 0px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.TextField1 input,
.TextField1 div {
  padding: 3px 8px;
}

.TextField1 fieldset {
  border-color: #D6D6D6 !important;
}

.description-agent p {
  margin: 0;
}
.labelbox-bg .formlabelbox{
  width: 100%;
  height: 100%;
  min-height: 33px;
}